/* Profile Layout */
.profile-container {
  display: flex;
  max-width: 1100px;
  margin: 30px auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  min-height: 50vh;
}

.sidebar {
  width: 250px;
  background: #f9f9f9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
}

.sidebar-link {
  /* display: block; */
  padding: 7px 10px;
  margin: 10px 0;
  font-size: large;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
  font-family: "Alegreya", serif;
  font-weight: 500;
}

.sidebar-link:hover,
.sidebar-link.active {
  background: cyan;
}

.profile-content {
  flex-grow: 1;
  padding: 30px;
}

.profile-section {
  max-width: 700px;
  margin: auto;
}

.profile-section h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.profile-section p {
  font-size: 16px;
  color: #666;
}

.profile-section-drafts-imgs div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.profile-section-drafts-imgs img {
  width: 13em;
  margin: 10px;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings-group {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.settings-group h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.settings-group label {
  display: block;
  font-size: 14px;
  margin: 5px 0;
  color: #333;
}

.settings-group input[type="text"],
.settings-group input[type="email"],
.settings-group input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.save-btn {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
}

.save-btn:hover {
  background: #0056b3;
}

@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    min-height: 70vh;
    margin: 0 15px;
  }

  .profile-section-drafts-imgs div {
    justify-content: center;
  }

  .profile-section-drafts-imgs img {
    width: 7em;
    margin: 10px;
  }

  .sidebar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-right: none;
    border-bottom: 1px solid #ddd;
    overflow-x: scroll;
    padding: 10px 20px;
  }

  .sidebar-link {
    padding: 7px;
    text-align: center;
    margin: 0 10px;
  }

  .profile-content {
    padding: 20px;
  }
}
